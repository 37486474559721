import { useEffect, useRef, useState } from "react"
import { usePropChange } from "./usePropChange"

const SECOND = 1_000
const MINUTE = SECOND * 60

export interface Timer {
    time: string
    start: () => void
    pause: () => void
    restart: (deadline: number) => void
    toggle: () => void
    setTimeAndPause: (deadline: number) => void
}

export default function useCountDown(deadline: number, onCountDownEnds?: () => void, interval = SECOND) {
    const [timeSpan, setTimeSpan] = useState(deadline * 1000)
    const [isPlaying, setIsPlaying] = useState(false)
    const intervalId = useRef<NodeJS.Timeout | null>(null)
    usePropChange(deadline, () => {
        setTimeSpan(deadline * 1000)
    })

    useEffect(() => {
        return () => {
            intervalId.current && clearInterval(intervalId.current)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const start = () => {
        pause()
        setIsPlaying(true)
        intervalId.current = setInterval(() => {
            setTimeSpan((previousTimeSpan) => {
                const newTimeSpan = previousTimeSpan - interval
                if (newTimeSpan <= 0) {
                    pause()
                    onCountDownEnds && onCountDownEnds()
                    return 0
                }
                return newTimeSpan
            })
        }, interval)
    }

    const pause = () => {
        setIsPlaying(false)
        intervalId.current && clearInterval(intervalId.current)
        intervalId.current = null
    }

    const toggle = () => {
        if (isPlaying) {
            pause()
        } else {
            start()
        }
        setIsPlaying(!isPlaying)
    }

    const restart = (deadline: number) => {
        setTimeSpan(deadline * 1000)
        if (!isPlaying) start()
    }

    const setTimeAndPause = (deadline: number) => {
        pause()
        setTimeSpan(deadline * 1000)
    }

    return {
        time: formatTime(timeSpan),
        start: start,
        pause: pause,
        restart: restart,
        toggle: toggle,
        setTimeAndPause
    } as Timer
}

function formatTime(timeSpan: number) {
    const minutes = Math.floor((timeSpan / MINUTE) % 60)
    const seconds = Math.floor((timeSpan / SECOND) % 60)

    const minutesFormatted = minutes > 9 ? minutes : minutes
    const secondsFormatted = seconds > 9 ? seconds : "0" + seconds

    return minutesFormatted + ":" + secondsFormatted
}
