import styled from "styled-components"
import { IconChevronDown, IconChevronUp } from "./Icons"
import { Button } from "./Button"
import { LocalStored } from "@hookstate/localstored"
import { State } from "@hookstate/core"
import { Game, Player, countTypeGame, countTypePlayer } from "../pages/WelcomePage"
import { CONTAINER_MAX_WIDTH } from "../styles/styles"

export type countType = countTypeGame | countTypePlayer

export interface CounterBoxPropsGame {
    state: State<Game, LocalStored>
    type: countTypeGame
    isRed: boolean
}

export interface CounterBoxPropsPlayer {
    player: State<Player, LocalStored>
    game: State<Game, LocalStored>
    type: countTypePlayer
    isRed: boolean
}

interface Counting {
    count: State<number, LocalStored>
    game: State<Game, LocalStored>
    getIconOrLabel: React.ReactNode | countTypePlayer
    isRed: boolean
    countable: boolean
    countTypePlayer?: countTypePlayer
    countTypeGame?: countTypeGame
}

export const CounterBoxBase: React.FunctionComponent<Counting> = (props) => {
    const countUp = () => {
        props.count.set((prev) => prev + 1)
        if (props.countTypePlayer === "knights") props.game.activeKnights.set((prev) => prev + 1)
        if (props.countTypePlayer === "cities") props.game.piratePower.set((prev) => prev + 1)
    }

    const countDown = () => {
        if (props.count.get() <= 0) return
        props.count.set((prev) => prev - 1)
        if (props.countTypePlayer === "knights") props.game.activeKnights.set((prev) => prev - 1)
        if (props.countTypePlayer === "cities") props.game.piratePower.set((prev) => prev - 1)
    }

    return (
        <Root $isRed={props.isRed}>
            <IconContainer>{props.getIconOrLabel}</IconContainer>
            {props.countable && (
                <ButtonContainer $isRed={props.isRed}>
                    <ButtonUpper onClick={countUp} className="button-upper" customStyle={"height: 50%; overflow: hidden;"}>
                        <IconChevronUp width="100%" height="22"></IconChevronUp>
                    </ButtonUpper>
                    <ButtonLower onClick={countDown} $isRed={props.isRed}>
                        <IconChevronDown width="100%" height="22"></IconChevronDown>
                    </ButtonLower>
                </ButtonContainer>
            )}
            {!props.countable && <DividerHorizontal $isRed={props.isRed}></DividerHorizontal>}
            <NumberContainer>
                <Number>{props.count.get()}</Number>
            </NumberContainer>
        </Root>
    )
}

const Root = styled.div<{ $isRed: boolean }>`
    display: flex;
    flex-direction: row;
    border: 1px solid ${(props) => (props.$isRed ? "red" : "black")};
    min-width: 170px;
    height: 50px;
    border-radius: 10px;
`

const DividerHorizontal = styled.div<{ $isRed: boolean }>`
    border-right: 1px solid ${(props) => (props.$isRed ? "red" : "black")};
`

const IconContainer = styled.div`
    margin: auto;
    padding: 0 10px;
    user-select: none;
    max-width: 35px;
`

const ButtonContainer = styled.div<{ $isRed: boolean }>`
    width: 33%;
    height: 100%;
    border-left: 1px solid ${(props) => (props.$isRed ? "red" : "black")};
    border-right: 1px solid ${(props) => (props.$isRed ? "red" : "black")};
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    @media ${CONTAINER_MAX_WIDTH.M} {
        width: 50%;
        flex-direction: row;
    }
`

const ButtonUpper = styled(Button)<{ $isRed: boolean }>`
    @media ${CONTAINER_MAX_WIDTH.M} {
        width: 50%;
        flex-direction: row;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-right: 1px solid ${(props) => (props.$isRed ? "red" : "black")};
    }
`
const ButtonLower = styled(Button)<{ $isRed: boolean }>`
    border-top: 1px solid ${(props) => (props.$isRed ? "red" : "black")};
    overflow: hidden;
    height: 50%;

    @media ${CONTAINER_MAX_WIDTH.M} {
        width: 50%;
        flex-direction: row;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-top: 0;
    }
`

const NumberContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 33%;
    justify-content: center;
    max-width: 35px;
`
const Number = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    user-select: none;
    font-size: 30px;
`
