import styled from "styled-components"
import { Button } from "../components/Button"

export const BlurText = styled.span`
    color: white;
`

export const HeaderButton = styled(Button)`
    padding: 5px 10px;
    border: solid black 0.5px;
    border-radius: 5px;
    color: white;
    font-size: 18px;
    display: inline-flex;
    align-items: center;
`
export const CenterVertically = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
`

export const TimerBox = styled(HeaderButton)`
    width: 54px;
    justify-content: center;
`

export const Input = styled.input<{ $color: string }>`
    outline: none;
    border: none;
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    color: ${(props) => props.$color};
    font-size: 20px;
    border-radius: 5px;

    text-overflow: ellipsis;
`

export const TimerInput = styled(Input)`
    text-align: center;
    padding: 0 !important;
    &::placeholder {
        color: ${(props) => props.$color};
    }
`

export const FadeAnimation = styled.div`
    animation: fade 1s ease-in-out 0s infinite forwards;
    @keyframes fade {
        0%,
        100% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
    }
`

export const CONTAINER_MAX_WIDTH_NUMBER = {
    XS: 380,
    S: 480,
    M: 768,
    XM: 950,
    L: 1024,
    XL: 1200,
    XXL: 1270
} as const

export const CONTAINER_MAX_WIDTH = {
    XS: `(max-width: ${CONTAINER_MAX_WIDTH_NUMBER.XS}px)`,
    S: `(max-width: ${CONTAINER_MAX_WIDTH_NUMBER.S}px)`,
    M: `(max-width: ${CONTAINER_MAX_WIDTH_NUMBER.M}px)`,
    XM: `(max-width: ${CONTAINER_MAX_WIDTH_NUMBER.XM}px)`,
    L: `(max-width: ${CONTAINER_MAX_WIDTH_NUMBER.L}px)`,
    XL: `(max-width: ${CONTAINER_MAX_WIDTH_NUMBER.XL}px)`,
    XXL: `(max-width: ${CONTAINER_MAX_WIDTH_NUMBER.XXL}px)`
} as const
