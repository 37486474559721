import { Icon, IconProps, VGIcon } from "./Icon"

export const IconChevronUp: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="chevron-up" props={props} />
}

export const IconChevronDown: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="chevron-down" props={props} />
}

export const IconPlus: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="plus" props={props} />
}

export const IconMinus: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="minus" props={props} />
}

export const IconSword: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="sword" props={props} />
}

export const IconRoad: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="road" props={props} />
}

export const IconTrophy: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="trophy" props={props} />
}
export const IconPirate: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="pirate-ship" props={props} />
}
export const IconSwordActive: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="sword-active" props={props} />
}
export const IconReset: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="reset" props={props} />
}
export const IconCity: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="city" props={props} />
}
