import styled from "styled-components"

interface ButtonProps extends React.HTMLAttributes<HTMLDivElement> {
    customStyle?: string
    // children?: React.ReactNode
    // onClick?: () => void
}

export const Button: React.FunctionComponent<ButtonProps> = (props) => {
    const { customStyle, children, ...rest } = props
    return (
        <ButtonRoot $customStyle={customStyle} {...rest}>
            {children}
        </ButtonRoot>
    )
}

const ButtonRoot = styled.div<{ $customStyle?: string }>`
    user-select: none;
    &:hover {
        background-color: rgba(224, 224, 224, 0.47);
    }
    &:active {
        background-color: rgba(142, 142, 142, 0.81);
    }

    cursor: pointer;

    ${(props) => props.$customStyle}
`
