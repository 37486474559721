import { useState } from "react"

/**
 * Use this to avoid useEffect: Instead of having a useEffect with a property as its dependency,
 * use this hook. But first, check if you can even avoid this hook, by calculating everything
 * during rendering. Further Reading:
 * https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
 *
 * @param prop You would like to react to this property changing.
 * @param onPropChange Pass a callback to do something when prop changes.
 */
export function usePropChange(prop: any, onPropChange: () => void) {
    const propAsString = JSON.stringify(prop)
    const [previousProp, setPreviousProp] = useState(propAsString)
    if (propAsString !== previousProp) {
        setPreviousProp(propAsString)
        onPropChange()
    }
}
