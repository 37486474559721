import { useState } from "react";
import styled from "styled-components";

interface SecretBoxProps {
  onClick: () => void;
}

export const SecretBox: React.FunctionComponent<SecretBoxProps> = (props) => {
  return (
    <Root>
      <Box width={100} onClick={props.onClick} onTouchStart={props.onClick}>
        <Box width={60}>
          <Box width={20}></Box>
        </Box>
      </Box>
    </Root>
  );
};

const Root = styled.div`
  background-color: black;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Box = styled.div<{ width: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.width}px;
  height: ${(props) => props.width}px;
  border: solid salmon 12px;
  cursor: pointer;
`;
