import styled from "styled-components"
import { PlayerBox } from "../components/PlayerBox"
import { useState } from "react"
import { IconPlus, IconReset } from "../components/Icons"
import { useHookstate, State, none } from "@hookstate/core"
import { localstored } from "@hookstate/localstored"
import { BlurText, CONTAINER_MAX_WIDTH, CenterVertically, HeaderButton } from "../styles/styles"
import { CounterBoxGame } from "../components/CounterBoxGame"
import { Timer } from "../components/Timer"
import { turn } from "../logic/globalStates"

interface WelcomePageProps {}

export interface Player {
    id: number
    name: string
    knights: number
    victoryPoints: number
    roads: number
    cities: number
}

export interface Game {
    piratePower: number
    activeKnights: number
    currentLongestRoad: string
}

export type countTypePlayer = "knights" | "victoryPoints" | "roads" | "cities"
export type countTypeGame = "piratePower" | "activeKnights"

export const WelcomePage: React.FunctionComponent<WelcomePageProps> = (props) => {
    const [showBlur, setShowBlur] = useState(true)
    const playerTurn = useHookstate(turn)
    const players: State<Player[]> = useHookstate([] as Player[], localstored({ key: "players" }))
    const game: State<Game> = useHookstate(
        { piratePower: players.length ?? 0, activeKnights: 0, currentLongestRoad: "" } as Game,
        localstored({ key: "game" })
    )
    let showWarning = false
    players.forEach((player) => {
        if (player.name.get() === "") {
            showWarning = true
            return
        }
        players.forEach((opponent) => {
            if (player.id.get() !== opponent.id.get() && player.name.get() === opponent.name.get()) {
                showWarning = true
                return
            }
        })
    })

    const addPlayer = () => {
        const newId = players.length
        game.piratePower.set((prev) => prev + 1)
        players.merge([{ id: newId, name: "", knights: 0, victoryPoints: 3, roads: 2, cities: 1 }])
    }

    const removePlayer = (playerIndex: number) => {
        game.piratePower.set((prev) => prev - 1)
        players[playerIndex].set(none)
    }

    const resetGame = () => {
        players.forEach((player) => {
            player.merge({
                knights: 0,
                victoryPoints: 3,
                roads: 2,
                cities: 1
            })
        })
        game.merge({ piratePower: players.length ?? 0, activeKnights: 0, currentLongestRoad: "" })
    }

    const calculateLongestRoad = (currentPlayer: State<Player>) => {
        if (currentPlayer.roads.get() < 5) return false
        let winCount = 0
        if (game.currentLongestRoad.get() === currentPlayer.name.get()) {
            players.forEach((opponent) => {
                if (opponent.name.get() !== currentPlayer.name.get() && opponent.roads.get() <= currentPlayer.roads.get()) {
                    winCount++
                }
            })
        } else {
            players.forEach((opponent) => {
                if (opponent.name.get() !== currentPlayer.name.get() && opponent.roads.get() < currentPlayer.roads.get()) {
                    winCount++
                }
            })
        }

        const iHaveLongestRoad = winCount === players.length - 1
        if (iHaveLongestRoad) {
            game.currentLongestRoad.set(currentPlayer.name.get())
        }
        return iHaveLongestRoad
    }

    const calculateWin = (currentPlayer: State<Player>) => {
        let winCount = 0
        players.forEach((opponent) => {
            if (
                opponent.name.get() !== currentPlayer.name.get() &&
                opponent.victoryPoints.get() <= currentPlayer.victoryPoints.get()
            ) {
                winCount++
            }
        })
        return winCount === players.length - 1
    }

    const nextPlayer = () => {
        playerTurn.set((prev) => {
            const nextPlayerIndex = (prev + 1) % players.length
            return nextPlayerIndex
        })
    }

    return (
        <Root>
            {/* <MenuBar>
                <MenuItem>Creative Projects</MenuItem>
                <MenuItem>Job Stuff</MenuItem>
                <MenuItem>About</MenuItem>
            </MenuBar> */}
            <GlassBoxWelcomePage $blur={showBlur}>
                <Header>
                    <Heading>Catan Counter</Heading>
                    <Timer onEnd={nextPlayer}></Timer>
                    <HeaderButton onClick={addPlayer}>
                        <CenterVertically>
                            <IconPlus width="15" height="15" stroke="white"></IconPlus>
                        </CenterVertically>
                    </HeaderButton>
                    <HeaderButton onClick={resetGame}>
                        <CenterVertically className="center-vertically">
                            <IconReset
                                width="25"
                                height="25"
                                fill="white"
                                customStyle={"margin-top: 5px; margin-left: 1px;"}
                            ></IconReset>
                        </CenterVertically>
                    </HeaderButton>
                    <CounterBoxGame type="piratePower" state={game} isRed={game.piratePower.get() > game.activeKnights.get()} />
                    <CounterBoxGame type="activeKnights" state={game} isRed={false} />
                    <HeaderButton onClick={() => setShowBlur((prev) => !prev)}>
                        <BlurText>blur</BlurText>
                    </HeaderButton>
                </Header>
                <PlayerBoxesContainer>
                    {players.map((player: State<Player>, index) => {
                        return (
                            <PlayerBox
                                key={index}
                                player={player}
                                players={players}
                                game={game}
                                removePlayer={removePlayer}
                                index={index}
                                hasLongestRoad={calculateLongestRoad(player)}
                                isWinning={calculateWin(player)}
                            ></PlayerBox>
                        )
                    })}
                </PlayerBoxesContainer>
                {showWarning && <ParagraphRed>*Player names must be unique and not empty.</ParagraphRed>}
            </GlassBoxWelcomePage>
        </Root>
    )
}

const Root = styled.div`
    width: 100%;
    min-height: 100%;
    width: -webkit-fill-available;
    position: absolute;
    top: 0;
    left: 0;
    background-attachment: fixed;
    background-image: url(20230730_182436.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px;
    box-sizing: border-box;

    @media ${CONTAINER_MAX_WIDTH.M} {
        padding: 0;
    }
`

export const Text = styled.span`
    color: white;
    font-size: 18px;
`
export const Paragraph = styled(Text)`
    margin-top: 20px;
`

export const ParagraphRed = styled(Paragraph)`
    color: red;
`

const PlayerBoxesContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
`

const Header = styled.div`
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
`

const Heading = styled(Text)`
    font-size: 24px;
    margin: auto;
`

// const MenuBar = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: flex-start;
//     flex-direction: row;
// `

// const MenuItem = styled.div`
//     margin: 5px 30px;
//     cursor: pointer;
//     flex-wrap: nowrap;
// `
export const GlassBox = styled.div<{ $blur?: boolean }>`
    ${(props) => (props.$blur ? "backdrop-filter: blur(15px);" : "")}
`
export const GlassBoxWelcomePage = styled(GlassBox)`
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    border-radius: 10px;
    border: solid black 0.5px;
    @media ${CONTAINER_MAX_WIDTH.M} {
        border: none;
    }
`
