import styled from "styled-components"
import { LocalStored } from "@hookstate/localstored"
import { State } from "@hookstate/core"
import { IconMinus } from "./Icons"
import { Game, Player } from "../pages/WelcomePage"
import { CounterBoxPlayer } from "./CounterBoxPlayer"
import { CONTAINER_MAX_WIDTH, HeaderButton, Input } from "../styles/styles"
import { useHookstate } from "@hookstate/core"
import { turn } from "../logic/globalStates"

interface PlayerBoxProps {
    player: State<Player, LocalStored>
    players: State<Player[], LocalStored>
    game: State<Game, LocalStored>
    isWinning: boolean
    hasLongestRoad: boolean
    removePlayer: (playerIndex: number) => void
    index: number
}

export const PlayerBox: React.FunctionComponent<PlayerBoxProps> = (props) => {
    const inputChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.player.name.set(event.target.value)
    }
    const playerTurn = useHookstate(turn)
    const myTurn = playerTurn.get() === props.player.id.get()

    return (
        <BoxRoot $myTurn={myTurn}>
            <Header $myTurn={myTurn}>
                <Input
                    value={props.player.name.get()}
                    onChange={inputChangeHandler}
                    placeholder={"Player Name"}
                    $color={props.isWinning ? "red" : myTurn ? "#4eef42" : "white"}
                />
                <RemovePlayerButton
                    onClick={() => {
                        props.removePlayer(props.index)
                    }}
                >
                    <IconMinus width="15" height="15" stroke="white"></IconMinus>
                </RemovePlayerButton>
            </Header>
            <AllCountersContainer className="all-counters-container">
                <CounterColumn className="counter-column-1">
                    <CounterBoxPlayer type="knights" player={props.player} isRed={false} game={props.game} />
                    <CounterBoxPlayer type="cities" player={props.player} isRed={false} game={props.game} />
                </CounterColumn>

                <CounterColumn className="counter-column-2">
                    <CounterBoxPlayer type="roads" player={props.player} isRed={props.hasLongestRoad} game={props.game} />
                    <CounterBoxPlayer type="victoryPoints" player={props.player} isRed={props.isWinning} game={props.game} />
                </CounterColumn>
            </AllCountersContainer>
        </BoxRoot>
    )
}

const RemovePlayerButton = styled(HeaderButton)`
    margin: 0 0;
`

const CounterColumn = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: stretch;
    @media ${CONTAINER_MAX_WIDTH.M} {
        width: 100%;
    }
`

const AllCountersContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    gap: 10px;
    justify-content: space-between;
`

const Header = styled.div<{ $myTurn: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: ${(props) => (props.$myTurn ? "1px solid #4eef42" : "1px solid black")};
    padding: 10px;
    user-select: none;
`

const BoxRoot = styled.div<{ $myTurn: boolean }>`
    border: ${(props) => (props.$myTurn ? "1px solid #4eef42" : "1px solid black")};
    border-radius: 10px;
    box-sizing: border-box;
    width: fit-content;
    height: fit-content;
    backdrop-filter: blur(15px);
`
