import { useState } from "react"
import { SecretBox } from "../components/SecretBox"
import styled from "styled-components"
import { WelcomePage } from "./WelcomePage"

interface HomePageProps {}

export const HomePage: React.FunctionComponent<HomePageProps> = (props) => {
    const [counter, setCounter] = useState(0)
    const content =
        counter >= 0 ? <WelcomePage></WelcomePage> : <SecretBox onClick={() => setCounter((prev) => prev + 1)}></SecretBox>
    return <Root>{content}</Root>
}

const Root = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
