import { IconCity, IconRoad, IconSword, IconTrophy } from "./Icons"
import { CounterBoxBase, CounterBoxPropsPlayer, countType } from "./CounterBoxBase"

export const CounterBoxPlayer: React.FunctionComponent<CounterBoxPropsPlayer> = (props) => {
    const count = props.player[props.type]

    const getIcon = (type: countType) => {
        switch (type) {
            case "knights":
                return <IconSword width="35" height="35"></IconSword>
            case "victoryPoints":
                return <IconTrophy width="40" height="40"></IconTrophy>
            case "roads":
                return <IconRoad width="35" height="35"></IconRoad>
            case "cities":
                return <IconCity width="40" height="40"></IconCity>
            default:
                return null
        }
    }
    return (
        <CounterBoxBase
            count={count}
            getIconOrLabel={getIcon(props.type) ?? props.type}
            isRed={props.isRed}
            countable
            game={props.game}
            countTypePlayer={props.type}
        ></CounterBoxBase>
    )
}
