import { TimerInput, TimerBox, FadeAnimation } from "../styles/styles"
import useCountDown from "../logic/useCountDown"
import { useRef, useState } from "react"
import { useHookstate, State } from "@hookstate/core"
import { localstored } from "@hookstate/localstored"

interface TimerProps {
    onEnd: () => void
}
export const Timer: React.FunctionComponent<TimerProps> = (props) => {
    const roundLength: State<number> = useHookstate(120, localstored({ key: "round-length" }))
    const onEnd = () => {
        timer.restart(roundLength.get())
        props.onEnd()
    }
    const timer = useCountDown(roundLength.get(), onEnd)
    const doubleClickTimer = useRef<NodeJS.Timeout | null>(null)
    const [edit, setEdit] = useState(false)

    const inputChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (event: React.ChangeEvent<HTMLInputElement>) => {
        const time = parseInt(event.target.value)
        if (isNaN(time)) return
        roundLength.set(time)
        timer.setTimeAndPause(time)
    }

    const handleDoubleClick = () => {
        setEdit(!edit)
        if (edit) return
        timer.pause()
    }

    return (
        <TimerBox
            onClick={() => {
                if (!doubleClickTimer.current) {
                    doubleClickTimer.current = setTimeout(() => {
                        // do single click stuff
                        timer.toggle()
                        doubleClickTimer.current = null
                    }, 300)
                } else {
                    // do double click stuff
                    handleDoubleClick()
                    clearTimeout(doubleClickTimer.current)
                    doubleClickTimer.current = null
                }
            }}
        >
            {edit ? (
                <FadeAnimation>
                    <TimerInput
                        autoFocus
                        onChange={inputChangeHandler}
                        onKeyUp={(e: React.KeyboardEvent) => {
                            if (e.key === "Enter" || e.key === "Spacebar" || e.key === " " || e.code === "Space") {
                                setEdit(!edit)
                            }
                        }}
                        placeholder={timer.time}
                        $color={"white"}
                    />
                </FadeAnimation>
            ) : (
                timer.time
            )}
        </TimerBox>
    )
}
