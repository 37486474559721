import { IconPirate, IconSword } from "./Icons"
import { CounterBoxBase, CounterBoxPropsGame } from "./CounterBoxBase"
import { countTypeGame } from "../pages/WelcomePage"

export const CounterBoxGame: React.FunctionComponent<CounterBoxPropsGame> = (props) => {
    const count = props.state[props.type]

    const getIcon = (type: countTypeGame) => {
        switch (type) {
            case "activeKnights":
                return <IconSword width="25" height="25"></IconSword>
            case "piratePower":
                return <IconPirate width="25" height="25"></IconPirate>
            default:
                return null
        }
    }
    return (
        <CounterBoxBase
            count={count}
            getIconOrLabel={getIcon(props.type) ?? props.type}
            isRed={props.isRed}
            countable={false}
            countTypeGame={props.type}
            game={props.state}
        ></CounterBoxBase>
    )
}
