import { ReactElement, useCallback } from "react"
import { ReactSVG } from "react-svg"
import styled from "styled-components"

export interface IconProps {
    stroke?: string
    width?: string
    height?: string
    fill?: string
    viewBox?: string
    customStyle?: string
}

export type VGIcon = ReactElement | null

export const Icon: React.FC<{ name: string; props?: IconProps }> = (propsAttr): VGIcon => (
    <IconWithUrl url={`/icons/${propsAttr.name}.svg`} {...propsAttr} />
)

export const IconWithUrl: React.FC<{ url: string; props?: IconProps }> = (propsAttr): VGIcon => {
    const { url, props } = propsAttr
    const replaceProps = (str: string, props: IconProps) => {
        for (let [key, value] of Object.entries(props!)) {
            if (!value) {
                continue
            }
            str = str.replace(new RegExp(`\\{${key}\\}`, "gi"), value)
        }
        return str
    }

    const beforeInjection = useCallback(
        (svg: SVGElement) => {
            if (!props) return

            const newAttributes = []
            for (let i = 0; i < svg.attributes.length; i++) {
                const attr = svg.attributes[i]
                newAttributes.push({ name: attr.name, value: replaceProps(attr.value, props) })
            }
            for (let i = 0; i < newAttributes.length; i++) {
                const attr = newAttributes[i]
                svg.setAttribute(attr.name, attr.value)
            }
            svg.innerHTML = replaceProps(svg.innerHTML, props)
        },
        [props]
    )

    return (
        <Root>
            <ReactSVG beforeInjection={beforeInjection} src={url} />
        </Root>
    )
}

const Root = styled.div`
    /* width: 100%;
    height: 100%; */
`
